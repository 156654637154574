<template>
  <div class="home">
    <div class="flex opoption">
      <div style="width:90% ;">
        <input class="input" v-model="keywords" placeholder="搜索" @keyup.enter="onSearch" @blur="onSearch" />
        <i class="icon-search" @click="onSearch"></i>
      </div>
    </div>
    <van-pull-refresh v-model="refreshing" @refresh="onLoad">
      <van-list :finished="finished" finished-text="没有更多了">
        <div class="home_center" v-if="listData.length">
          <div v-for="(item,index) in listData" :key="index">
            <div class="home_center_dic">
              <div class="title" @click="yunan(item)">{{ item.title }}</div>
              <div style="display: flex;">
                 <div class="titlesize" @click="loadPDF(item)">预览</div>
                <div class="titlesize" @click="xizha(item)">下载</div>
              </div>
            </div>
          </div>
        </div>
        <div v-else style="margin-top:35%">
          <van-empty description="空空如也" />
        </div>
      </van-list>
    </van-pull-refresh>
    <canvas ref="canvas"></canvas>
  </div>
</template>

<script>
import axios from "axios";
import { Toast } from "vant";
export default {
  data() {
    return {
      imgURL: "https://img01.yzcdn.cn/vant/custom-empty-image.png",
      listData: [], //查询的数据
      page: 1,//当前分页
      size: 20,
      keywords: "",
      tatol: null,//总条数
      finished: false,//是否加载全部
      refreshing: false,//是否显示加载动画
      last_page: 1,
      previewFile:false,
      preFileUrl:null

    };
  },
  // 生命周期 - 创建完成（访问当前this实例）
  created() {

  },
  // 生命周期 - 挂载完成（访问DOM元素）
  mounted() {
    this.list();
  },
  //  注册组件
  components: {
  },
  // 计算属性
  computed: {},
  // 方法合集
  methods: {
    async loadPDF (item) {
      let url = '/pdfjs/web/viewer.html?file=/api' + item.file
      window.open(url, '_blank')
      // const loadingTask = pdfjs.getDocument(item.file)
      // const pdf = await loadingTask.promise
      // const page = await pdf.getPage(1)
      // const canvas = this.$refs.canvas
      // const context = canvas.getContext('2d')
      // const viewport = page.getViewport({ scale: 1 })
      // canvas.height = viewport.height
      // canvas.width = viewport.width
      // const renderContext = {
      //   canvasContext: context,
      //   viewport: viewport
      // }
      // await page.render(renderContext)
    },
    onLoad() {
      const that = this;
      that.page += 1
      that.refreshing = true
      if (that.page > that.last_page && that.last_page != null) {
        that.refreshing = false;
        that.finished = true;
      } else {
        const data = {
          size: that.size,
          page: that.page,
          keywords: that.keywords
        };
        axios.post("/api/home/link/file_list", data).then(respose => {
          var res = respose.data;
          if (res.code == 200) {
            that.refreshing = false;
            that.last_page = res.data.last_page;
            var list = res.data.data;
            that.listData = that.listData.concat(list);
            that.$forceUpdate();
          }
        });
      }
    },
    list() {
      const that = this;
      const data = {
        size: that.size,
        page: that.page,
        keywords: that.keywords
      };
      axios.post("/api/home/link/file_list", data).then(res => {
        if (res.data.code == 200) {
          console.log(res.data)
          this.listData = res.data.data.data;
          that.last_page = res.data.last_page;
        }
      });
    },
    //查询样衣
    // 确认关闭弹窗 打开二维码
    // input搜索事件
    onSearch() {
      this.refreshing = true;
      var data = {
        size: this.size,
        page: 1,
        keywords: this.keywords
      };
      that.page = 1
      axios.post("/api/home/link/file_list", data).then(respose => {
        var res = respose.data;
        if (res.code == 200) {
          this.tatol = res.data.total;
          let list = res.data.data;
          this.listData = list;
          this.loading = false;
        }
      });
    },
    // 复制链接
    async copyLink() {
      try {
        const link = this.url_address; // 要复制的链接
        await navigator.clipboard.writeText(link);
        Toast.success("复制成功");
      } catch (err) {
        console.error("复制链接失败", err);
      }
    },
    yunan(item) {
      this.$router.push(`/iframe?k=${item.file}`);
    },
    xizha(item) {
      const link = document.createElement('a');
      link.href = item.file;
      link.download = item.title+'.pdf';
      link.click();
    },


    closePdf(val) {
      this.previewFile = val
    }
  }
};
</script>
<style  scoped lang="scss">
.home_center {
  margin-top: 60px;
  padding: 0px 4% 0px 4%;
  .home_center_dic {
    margin: 15px 0px;
    display: flex;
    border-bottom: 1px solid rgba(235, 235, 235, 1);
    padding: 5px 0px;
    .title {
      width: calc(100% - 120px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; // 溢出不换行
    }
    .titlesize {
      font-size: 12px;
      margin-left: 8px;
      background: rgba(42, 130, 228, 1);
      padding: 2px 10px;
      box-sizing: border-box;
      border-radius: 10px;
      color: #ffffff;
    }
  }
}
.opoption {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: white;
  z-index: 1000; /* 确保头部在最上层 */
  height: 60px;
  padding-left: 14px;
}

.home {
  width: 375px;
  /* 设置placeholder的字体样式 */
  padding: 14px 0px 14px 0px;
  box-sizing: border-box;
  min-height: 100vh;
  .flex {
    display: flex;
    align-items: center;
  }

  .input {
    width: 100%;
    height: 36px;
    opacity: 1;
    font-size: 16px;
    font-weight: 400;
    border-radius: 18px;
    background: rgba(217, 217, 217, 0.2);
    /* background: #f1f2f3; */
    outline: none;
    border: none;
    padding-left: 19px;
    box-sizing: border-box;
  }
  .icon-search {
    background: url(../assets/seach.png) no-repeat;
    background-size: 100% 100%;
    width: 16px;
    height: 16px;
    position: absolute;
    top: 22px;
    right: 15%;
  }
}
</style>
